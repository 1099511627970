import Members from 'components/adminCom/Members';
import DashboardLayout from 'components/layout/Layout';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AddMember from 'views/admin/AddMember';
import AdminDashboard from 'views/admin/AdminDashboard';
import DynamicTable from 'views/DynamicTable';
import Login from 'views/SignIn';

const Navigation = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/login');
        }
    }, [navigate]);
    const userRole = JSON.parse(localStorage.getItem("user"))
    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route element={<DashboardLayout />}>
                {userRole?.role === "admin" && (
                    <>
                        <Route path="/*" element={<DynamicTable />} />
                        <Route path="/dashboard" element={<AdminDashboard />} />
                        <Route path="/overall/add-member" element={<AddMember />} />
                        <Route path="/overall/all-member" element={<Members />} />
                    </>
                )}
                <Route path='/*' element={<DynamicTable />} />
                <Route path="/" element={<AdminDashboard />} />
                <Route path="/dashboard" element={<AdminDashboard />} />
            </Route>
        </Routes>
    );
};

export default Navigation;
