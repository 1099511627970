import { GetApiData } from "utils/http-client";
export const LoginForm = function (data) {
  return GetApiData(`/user/login`, "POST", data, true);
};
export const UsersCreate = function (data) {
  return GetApiData(`/user/sign-up`, "POST", data, true);
};
export const GetAllUser = function (query) {
  return GetApiData(`/user/get-users?search=${query}`, "GET", null, true);
};
export const UserStatus = function (id,status) {
  return GetApiData(`/user/update-status/${id}`, "PUT", {
    "status" : status
}, true);
};

export const UserDelete = function (id) {
  return GetApiData(`/user/delete-user/${id}`, "Delete", null, true);
};