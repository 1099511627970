import Members from 'components/adminCom/Members';
import DataTable from 'components/shared/DataTable';
import Loader from 'components/shared/Loader';
import { useEffect, useState, useCallback } from 'react';
import { Accordion, Button, Card, Col, Container, Form } from 'react-bootstrap';
import { FaCaretDown, FaCaretRight, FaEye, FaEyeSlash } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { GetDashboardData, UpdateTableData } from 'services/Analytics';
import Swal from 'sweetalert2';
import { AuthName } from 'utils/auth.utils';
import debounce from 'lodash.debounce'; // Import lodash.debounce for debouncing the search input

const AdminDashboard = () => {
    const location = useLocation();
    const [openIndex, setOpenIndex] = useState([]);
    const [showAll, setShowAll] = useState(true);
    const [user] = useState(() => JSON.parse(localStorage.getItem("user")) || {});
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState('');
    const [loading, setLoading] = useState(false);
    const fullName = AuthName();

    const columnOptions = [
        { value: '', label: 'All Columns' },
        { value: 'dbc', label: 'DBC' },
        { value: 'zorgactiviteit', label: 'Zorgactiviteit' },
        { value: 'epic', label: 'Epic' },
        { value: 'hix', label: 'Hix' },
        { value: 'passantenprijs', label: '€' },
        { value: 'normtijdNvpc', label: 'Normtijd NVPC' },
        { value: 'machtiging', label: 'Machtiging' },
        { value: 'overnachting', label: 'Overnachting' }
    ];

    // Handle table update
    const handleUpdateTableData = async (data) => {
        try {
            const response = await UpdateTableData(data);
            return response;
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: "There was an error updating the data.",
            });
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const active = queryParams.get('active');
        active ? setShowAll(false) : setShowAll(true);
        const Index = data?.findIndex(i => i?.category.name === active);
        active ? setOpenIndex(Index !== -1 ? [Index] : []) :
            setOpenIndex(data?.map((_, index) => index));

    }, [location.search, data]);

    // Fetch data from the API
    const GetData = async () => {
        try {
            setLoading(true);
            const response = await GetDashboardData();
            if (response?.data) {
                const fetchedData = response.data.data;
                setData(fetchedData);
                setFilteredData(fetchedData); // Set both original and filtered data
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Network or server error',
                text: error.response?.data?.error,
                confirmButtonText: 'OK',
            });
        } finally {
            setLoading(false);
        }
    };

    const filterData = (query) => {
        if (!query) {
            setFilteredData(data);
            return;
        }

        const lowerCaseQuery = query.toLowerCase();

        const filtered = data.map((link) => {
            const subcategories = link.subcategories.map((sub) => {
                const matchingEntries = sub.entries.filter((entry) => {
                    if (selectedColumn) {
                        console.log("selected column",selectedColumn)
                        return entry[selectedColumn]?.toLowerCase().includes(lowerCaseQuery);
                    } else {
                        // Filter by all columns if no column is selected
                        return (
                            entry.dbc?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.zorgactiviteit?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.epic?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.hix?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.passantenprijs?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.normtijdNvpc?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.machtiging?.toLowerCase().includes(lowerCaseQuery) ||
                            entry.overnachting?.toLowerCase().includes(lowerCaseQuery)
                        );
                    }
                });

                return matchingEntries.length > 0 ? {
                    subcategory: sub.subcategory,
                    entries: matchingEntries,
                } : null;
            }).filter(Boolean);

            return subcategories.length > 0 ? {
                category: link.category,
                subcategories: subcategories,
            } : null;
        }).filter(Boolean);

        setFilteredData(filtered);
    };
    

    const debouncedSearch = useCallback(
        debounce((query) => {
            filterData(query);
        }, 300),
        [data,selectedColumn]
    );

    useEffect(() => {
        GetData(); 
    }, []);

    useEffect(() => {
        debouncedSearch(searchQuery); 
    }, [searchQuery, debouncedSearch]);

    const handleShowAll = () => {
        if (showAll) {
            setOpenIndex([]);
        } else {
            setOpenIndex(data.map((_, index) => index));
        }
        setShowAll(!showAll);
    };

    const handleToggle = (index) => {
        if (openIndex.includes(index)) {
            setOpenIndex(openIndex.filter((i) => i !== index));
        } else {
            setOpenIndex([...openIndex, index]);
        }
    };

    // Handle search input change
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleColumnChange = (e) => {
        setSelectedColumn(e.target.value); // Update the selected column
    };

    return (
        <Container fluid className="p-3">
            <h5 className='heading mb-2'>
                Good Morning,
                <span className='px-1'>
                    {fullName ? fullName : 'User'}
                </span>
            </h5>
            <div className="d-md-flex justify-content-between align-items-center px-4 py-2 position-sticky" style={{ top: -8, zIndex: 1000, background: "#F7FBFF" }}>
                <Col md={4} xs={12}>
                    <Form onSubmit={(e)=>e.preventDefault()}>
                        <div className='d-flex gap-2'>

                        <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearch}
                            style={{ borderColor: 'var(--color1)' }}
                        />
                          <Form.Select onChange={handleColumnChange} value={selectedColumn} style={{ borderColor: 'var(--color1)' }}>
                        {columnOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                    </div>
                    </Form>
                  
                </Col>

                <Button className="btn-custom mt-md-auto mt-2" onClick={handleShowAll}>
                    {showAll ? "Collapse All" : "Expand All"}
                </Button>
            </div>
            <Card className="p-4 shadow-sm border-0 rounded-4 mb-4">
                <h5 className="heading mb-4">Overall</h5>
                {loading ? (
                    <Loader />
                ) : (
                    <Accordion activeKey={openIndex}>
                        {filteredData?.map((link, index) => (
                            <Accordion.Item className='border-0 shadow-sm' eventKey={index} key={index}>
                                <Accordion.Header onClick={() => handleToggle(index)} className="custom-accordion-header">
                                    {openIndex.includes(index) ? (
                                        <FaEyeSlash className="me-2" style={{ color: 'var(--color1)' }} />
                                    ) : (
                                        <FaEye className="me-2" style={{ color: 'var(--color1)' }} />
                                    )}
                                    <span style={{ color: "#818180" }} className="flex-grow-1">{link?.category.name}</span>
                                    {openIndex.includes(index) ? (
                                        <FaCaretDown className="ms-2" style={{ color: 'var(--color1)' }} />
                                    ) : (
                                        <FaCaretRight className="ms-2" style={{ color: 'var(--color1)' }} />
                                    )}
                                </Accordion.Header>
                                <Accordion.Body className='p-0'>
                                    <DataTable apiData={link.subcategories} category={link.category} UpdateTableData={handleUpdateTableData} GetData={GetData} />
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                )}
            </Card>

            {user?.role === "admin" && <Members />}
        </Container>
    );
};

export default AdminDashboard;
